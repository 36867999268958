<script setup lang="ts">
defineProps<{
  text?: string
  disabled?: boolean
  link?: boolean
  size?: string
}>()

const { pending } = storeToRefs(useProjectStore())
</script>

<template>
  <NuxtLink
    v-if="link"
    :class="{
      'cursor-not-allowed bg-secondary-gray-400 text-secondary-gray-600':
        disabled,
      'px-spacing-sm py-spacing-sm sm:px-spacing-lg sm:py-spacing-sm2':
        size === 'small',
      'px-spacing-xs py-spacing-xs sm:px-spacing-md sm:py-spacing-sm':
        size === 'xs',
      'px-spacing-lg py-spacing-sm2': !size,
    }"
    class="secu-body-small-bold flex items-center justify-center rounded-full hover:opacity-90">
    <slot />{{ text }}
  </NuxtLink>
  <button
    v-else
    :disabled
    :class="{
      'cursor-not-allowed bg-secondary-gray-400 text-secondary-gray-600':
        disabled,
      'h-12': pending,
      'px-spacing-sm py-spacing-sm sm:px-spacing-lg sm:py-spacing-sm2':
        size === 'small',
      'px-spacing-xs py-spacing-xs sm:px-spacing-md sm:py-spacing-sm':
        size === 'xs',
      'px-spacing-lg py-spacing-sm2': !size,
    }"
    class="secu-body-small-bold flex items-center justify-center rounded-full hover:opacity-90">
    <slot /><span class="min-h-5">{{ text }}</span>
  </button>
</template>
